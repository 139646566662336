export const ADD_BOOKMARK = 'ADD_BOOKMARK';
export const GET_BOOKMARK = 'GET_BOOKMARK';
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK';
export const LIST_SCHEDULE = 'LIST_SCHEDULE';
export const LIST_MYSCHEDULE = 'LIST_MYSCHEDULE';
export const LIST_SPONSORS = 'LIST_SPONSORS';
export const ADD_REGISTRATION = 'ADD_REGISTRATION';
export const GET_REGISTRATION = 'GET_REGISTRATION';
export const UPDATE_REGISTRATION = 'GET_REGISTRATION';
export const DELETE_REGISTRATION = 'DELETE_REGISTRATION';
export const GET_REGISTRATIONS = 'GET_REGISTRATIONS';
export const UPDATE_REGISTRATIONS = 'UPDATE_REGISTRATIONS';
export const GET_ALL_REGISTRATIONS = 'GET_ALL_REGISTRATIONS';
export const UPDATE_ALL_REGISTRATIONS = 'UPDATE_ALL_REGISTRATIONS';
export const GET_USERSREPORTS = 'GET_USERSREPORTS';
